import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import global_da from "./Translations/da/lang.json";
import global_en from "./Translations/en/lang.json";
import global_tr from "./Translations/tr/lang.json";

i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem("lng") || "en",
  fallbackLng: "en",
  resources: {
    en: {
      global: global_en,
    },
    tr: { global: global_tr },
    da: { global: global_da },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);
