import { createContext, useContext, useState } from "react";

const MainContext = createContext();

const MainProvider = ({ children }) => {
  const [isOpenSideBar, setIsOpenSideBar] =
    useState();
    // window.innerWidth < 1024 ? false : true

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  // useEffect(() => {
  //   const handleResize = debounce(() => {
  //     if (window.innerWidth < 1024) {
  //       setIsOpenSideBar(false);
  //     } else {
  //       setIsOpenSideBar(true);
  //     }
  //   }, 300);

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  const values = {
    isOpenSideBar,
    setIsOpenSideBar,
    isOpenDrawer,
    setIsOpenDrawer,
  };

  return <MainContext.Provider value={values}>{children}</MainContext.Provider>;
};
const useMainContext = () => useContext(MainContext);
export { MainProvider, useMainContext };
