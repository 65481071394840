import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [profil, setProfile] = useState(
    JSON.parse(localStorage.getItem("user"))
    // {
    //   email: "yılmaz@huumi.dk",
    //   name: "Yılmaz Huumi",
    //   company: "huumi",
    //   isAdmin: false,
    // }
  );

  const login = (value) => {
    setProfile(value);
    localStorage.setItem("user", JSON.stringify(value));
    navigate("/dashboard");
  };

  const logOut = () => {
    localStorage.removeItem("user");
    navigate("/auth", { replace: true });
    setProfile("");
  };

  const values = {
    profil,
    setProfile,
    logOut,
    login,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
const useAuthContext = () => useContext(AuthContext);
export { AuthProvider, useAuthContext };
