import { ConfigProvider } from "antd";
import React, { lazy, Suspense } from "react";

import daDK from "antd/es/locale/da_DK";
import en from "antd/es/locale/en_US";
import trTR from "antd/locale/tr_TR";

import { useTranslation } from "react-i18next";

import "leaflet/dist/leaflet.css";

import { BrowserRouter } from "react-router-dom";
import SplashScreen from "./Components/Splash/SplashScreen";
import { AuthProvider } from "./Context/AuthContext";
import { MainProvider } from "./Context/MainContext";

const RoutesPage = lazy(() => import("./Routes/routes"));

const App = () => {
  const { i18n } = useTranslation("global");

  return (
    <BrowserRouter>
      <ConfigProvider
        locale={
          i18n.language === "tr" ? trTR : i18n.language === "da" ? daDK : en
        }
      >
        <MainProvider>
          <AuthProvider>
            <Suspense fallback={<SplashScreen />}>
              <RoutesPage />
            </Suspense>
          </AuthProvider>
        </MainProvider>
      </ConfigProvider>
    </BrowserRouter>
  );
};

export default App;
