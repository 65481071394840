// SplashScreen.js
import React from "react";

const SplashScreen = () => (
  <div className="flex flex-col gap-5 h-screen justify-center items-center">
    <p className="text-blue-500 text-xl md:text-4xl">Huumi</p>
    {/* <Spin size="large" tip="Yükleniyor..." /> */}
  </div>
);

export default SplashScreen;
